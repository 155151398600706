<template>
  <div id="PRIVACY_INTRO" class="wrapper">
    <header id="header" class="header">
      <div class="inner-box">
        <div class="logo">
          <h1>
            <a href="/privacy/intro"
              ><span class="hide">SK telink</span><em>프라이버시 센터</em></a
            >
          </h1>
        </div>
      </div>
    </header>

    <div id="container">
      <div class="contents">
        <div class="sub_top">
          <h2>개인정보 처리방침</h2>
          <div class="sch-box">
            <select id="previewVersion" v-model="selectedPrivacy">
              <option value="20241219">2024.12.19 ~ 현재까지 적용</option>
              <option value="20240822">2024.08.22 ~ 2024.12.18</option>
              <option value="20240718">2024.07.18 ~ 2024.08.21</option>
              <option value="20240321">2024.03.21 ~ 2024.07.17</option>
              <option value="20240222">2024.02.22 ~ 2024.03.20</option>
              <option value="20230818">2023.08.18 ~ 2024.02.21</option>
              <option value="20230425">2023.04.25 ~ 2023.08.17</option>
              <option value="20221227">2022.12.27 ~ 2023.04.24</option>
              <option value="20221108">2022.11.08 ~ 2022.12.26</option>
              <option value="20220831">2022.08.31 ~ 2022.11.07</option>
              <option value="20220603">2022.06.03 ~ 2022.08.30</option>
              <option value="20211203">2021.12.03 ~ 2022.06.02</option>
              <option value="20211001">2021.10.01 ~ 2021.12.02</option>
              <option value="20210404">2021.04.04 ~ 2021.09.30</option>
              <option value="20210120">2021.01.20 ~ 2021.04.03</option>
              <option value="20201109">2020.11.09 ~ 2021.01.19</option>
              <option value="20200717">2020.07.17 ~ 2020.11.08</option>
              <option value="20200522">2020.05.22 ~ 2020.07.16</option>
              <option value="20200320">2020.03.20 ~ 2020.05.21</option>
              <option value="20200117">2020.01.17 ~ 2020.03.19</option>
              <option value="20191108">2019.11.08 ~ 2020.01.16</option>
              <option value="20190823">2019.08.23 ~ 2019.11.07</option>
              <option value="20190125">2019.01.25 ~ 2019.08.22</option>
              <option value="20181219">2018.12.19 ~ 2019.01.24</option>
              <option value="20181026">2018.10.26 ~ 2018.12.18</option>
            </select>
          </div>
          <div class="desc">
            <p>
              SK텔링크는 고객의 개인정보 보호를 중요하게 생각하고, 고객의
              개인정보를 보호하기 위하여 항상 최선을 다해 노력합니다. SK텔링크는
              「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및
              「개인정보보호법」을 비롯한 모든 관련 개인정보 법률을 준수하고,
              관련 법률에 따라 다음과 같이 SK텔링크의「개인정보처리방침」을
              수립하고 이를 홈페이지(<a
                href="/privacy/intro"
                target="_blank"
                title="새창열림"
                >https://www.sktelink.com/privacy/intro</a
              >)에 공개하여 고객이 언제나 용이하게 열람할 수 있도록 하고
              있습니다.
            </p>
            <p>
              SK텔링크의 「개인정보처리방침」은 다음과 같은 내용으로 구성되어
              있습니다.
            </p>
          </div>
        </div>
        <!-- // contents -->

        <section class="explain-warp">
          <article class="terms-area">
            <div class="list-wrap">
              <ol class="terms-list">
                <li>
                  <a class="over_line" @click="scroll('terms_01')">1. 총칙</a>
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_02')"
                    >2. 개인정보의 수집, 이용 목적, 항목 및 보유기간</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_03')"
                    >3. 개인정보 수집 방법 및 동의 방법</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_04')"
                    >4. 개인정보 처리 업무의 위탁</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_05')"
                    >5. 수집한 개인정보 제3자 제공</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_06')"
                    >6. 개인정보 보유 및 이용기간</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_07')"
                    >7. 개인정보의 파기절차 및 방법</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_08')"
                    >8. 고객의 권리, 의무와 행사 방법</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_09')"
                    >9. 아동의 개인정보보호 및 법정대리인의 권리</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_10')"
                    >10. 개인정보의 자동 수집장치의 설치·운영 및 그 거부에 관한
                    사항</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_11')"
                    >11. 개인정보 보호를 위한 기술적·관리적 대책</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_12')"
                    >12. 광고성 정보전송</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_13')"
                    >13. 의견수렴 및 불만처리</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_14')"
                    >14. 고객 개인정보의 국외 이전</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_15')"
                    >15. 개인정보보호책임자 및 담당자</a
                  >
                </li>
                <li>
                  <a class="over_line" @click="scroll('terms_16')"
                    >16. 개인정보 처리방침 변경에 관한 사항</a
                  >
                </li>
              </ol>
            </div>
            <Latest
              v-if="selectedPrivacy === '20241219'"
              key="content_20241219"
            />
            <Old20240822
              v-if="selectedPrivacy === '20240822'"
              key="content_20240822"
            />
            <Old20240718
              v-if="selectedPrivacy === '20240718'"
              key="content_20240718"
            />
            <Old20240717
              v-if="selectedPrivacy === '20240321'"
              key="content_20240321"
            />
            <Old20240321
              v-if="selectedPrivacy === '20240222'"
              key="content_20240222"
            />
            <Old20240221
              v-if="selectedPrivacy === '20230818'"
              key="content_20230818"
            />

            <Old20230817
              v-if="selectedPrivacy === '20230425'"
              key="content_20230425"
            />
            <Old20230424
              v-if="selectedPrivacy === '20221227'"
              key="content_20221227"
            />
            <Old20221226
              v-if="selectedPrivacy === '20221108'"
              key="content_20221108"
            />
            <Old20221107
              v-if="selectedPrivacy === '20220831'"
              key="content_20220831"
            />
            <Old20220830
              v-if="selectedPrivacy === '20220603'"
              key="content_20220603"
            />
            <Old20220602
              v-if="selectedPrivacy === '20211203'"
              key="content_20211203"
            />
            <Old20211202
              v-if="selectedPrivacy === '20211001'"
              key="content_20211001"
            />
            <Old20210930
              v-if="selectedPrivacy === '20210404'"
              key="content_20210404"
            />
            <Old20210403
              v-if="selectedPrivacy === '20210120'"
              key="content_20210120"
            />
            <Old20210119
              v-if="selectedPrivacy === '20201109'"
              key="content_20201109"
            />
            <Old20201108
              v-if="selectedPrivacy === '20200717'"
              key="content_20200717"
            />
            <Old20200716
              v-if="selectedPrivacy === '20200522'"
              key="content_20200522"
            />
            <Old20200521
              v-if="selectedPrivacy === '20200320'"
              key="content_20200320"
            />
            <Old20200319
              v-if="selectedPrivacy === '20200117'"
              key="content_20200117"
            />
            <Old20200116
              v-if="selectedPrivacy === '20191108'"
              key="content_20191108"
            />
            <Old20191107
              v-if="selectedPrivacy === '20190823'"
              key="content_20190823"
            />
            <Old20190822
              v-if="selectedPrivacy === '20190125'"
              key="content_20190125"
            />
            <Old20190124
              v-if="selectedPrivacy === '20181219'"
              key="content_20181219"
            />
            <Old20181218
              v-if="selectedPrivacy === '20181026'"
              key="content_20181026"
            />
          </article>
          <div class="btn_bottom">
            <DownloadLink
              v-if="selectedPrivacy === '20221227'"
              key="download_20221227"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20221227.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20221108'"
              key="download_20221108"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20221108.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20220831'"
              key="download_20220831"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20220831.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20220603'"
              key="download_20220603"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20220603.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20211203'"
              key="download_20211203"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20211203.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20211001'"
              key="download_20211001"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20211001.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20210404'"
              key="download_20210404"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20210404.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20210120'"
              key="download_20210120"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20210120.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20201109'"
              key="download_20201109"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20201109.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20200717'"
              key="download_20200717"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20200717.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20200522'"
              key="download_20200522"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20200522.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20200320'"
              key="download_20200320"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20200320.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20200117'"
              key="download_20200117"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20200117.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20191108'"
              key="download_20191108"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20191108.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20190823'"
              key="download_20190823"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20190823.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20190125'"
              key="download_20190125"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20190125.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20181219'"
              key="download_20181219"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20181219.pdf'"
            />
            <DownloadLink
              v-if="selectedPrivacy === '20181026'"
              key="download_20181026"
              :download-link="'/content/privacy/SKTelink_Privacy_Attach_20181026.pdf'"
            />
          </div>
        </section>
      </div>
      <!-- // contents -->
    </div>

    <div id="footer">
      <div class="footer-inner">
        <div class="address">
          (04212) 서울시 마포구 마포대로 144 마포T타운&nbsp;&nbsp;
          사장&nbsp;&nbsp;최영찬
        </div>
        <div class="copyright">
          Copyright © SK Telink CO,LTD. All Rights Reserved.
        </div>
      </div>
    </div>
    <SummitControl />
  </div>
</template>

<script>
import Latest from "./content/Latest.vue";
import Old20240822 from "./content/20240822";
import Old20240718 from "./content/20240718";
import Old20240717 from "./content/20240717";
import Old20240321 from "./content/20240321";
import Old20240221 from "./content/20240221";
import Old20230817 from "./content/20230817";
import Old20230424 from "./content/20230424";
import Old20221226 from "./content/20221226";
import Old20221107 from "./content/20221107";
import Old20220830 from "./content/20220830";
import Old20220602 from "./content/20220602";
import Old20211202 from "./content/20211202";
import Old20210930 from "./content/20210930";
import Old20210403 from "./content/20210403";
import Old20210119 from "./content/20210119";
import Old20201108 from "./content/20201108";
import Old20200716 from "./content/20200716";
import Old20200521 from "./content/20200521";
import Old20200319 from "./content/20200319";
import Old20200116 from "./content/20200116";
import Old20191107 from "./content/20191107";
import Old20190822 from "./content/20190822";
import Old20190124 from "./content/20190124";
import Old20181218 from "./content/20181218";
import DownloadLink from "./DownloadLink";
import SummitControl from "@/components/SummitControl";

export default {
  name: "PrivacyIntro",
  metaInfo: {
    title: "개인정보 처리방침",
  },
  components: {
    Latest,
    Old20240822,
    Old20240718,
    Old20240717,
    Old20240321,
    Old20240221,
    Old20230817,
    Old20230424,
    Old20221226,
    Old20221107,
    Old20220830,
    Old20220602,
    Old20211202,
    Old20210930,
    Old20210403,
    Old20210119,
    Old20201108,
    Old20200716,
    Old20200521,
    Old20200319,
    Old20200116,
    Old20191107,
    Old20190822,
    Old20190124,
    Old20181218,
    DownloadLink,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {
      selectedPrivacy: "20241219",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.$root.$el.children[0].classList.add("type02");
  },
  methods: {
    scroll(id) {
      let position = document.getElementById(id).offsetTop - 94;
      window.scrollTo(0, position);
      document.getElementById("wrap").scrollTop = position;
    },
  },
};
</script>

<style lang="scss" scoped></style>
