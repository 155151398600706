<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg05">
          <h3 class="title title_esg">윤리경영</h3>
          <p class="txt txt_esg">
            SK텔링크는 윤리경영을 통해 <br />
            투명하고 건전한 기업문화를 만듭니다.
          </p>
          <img
            src="/content/images/sub_header_bg_esg05_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>

        <div class="company esg">
          <div class="cp_area pb0">
            <div class="cp_box">
              <h4>윤리경영의 정의 및 추진배경</h4>
              <p>
                윤리경영이란 기업이 경제적, 법률적 책임을 준수하는 것과
                더불어<br class="pc-only" />
                사회적 통념으로 요구되는 윤리적 기준을 기업의 의사결정과 행동에
                반영하는 것입니다.<br />
                또한, 건전한 기업시민의 역할(사회적 책임)이라고 할 수 있는
                재량적 책임(Discretional)을 수행하는 <br class="pc-only" />
                지속가능경영도 광의의 윤리경영이라고 할 수 있습니다.
              </p>
            </div>
          </div>

          <div class="esg_box">
            <div class="inner">
              <img
                src="/content/images/img_esg_ethical01.png"
                alt="윤리경영의 정의 및 추진배경"
              />
            </div>
            <ul class="esg_pdf_list">
              <li>
                <span class="pdf_title">윤리규범</span>
                <span class="pdf_txt"
                  >행동과 가치판단의 기준인 윤리규범을 제정하고 준수</span
                >
                <a
                  class="pdf_btn"
                  href="/content/download/윤리규범_SK텔링크_240903.pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li>
              <li>
                <span class="pdf_title">윤리실천지침</span>
                <span class="pdf_txt"
                  >윤리규범을 올바르게 이해하고 실천할 수 있도록 행동과 판단의
                  기준을 제공</span
                >
                <a
                  class="pdf_btn"
                  href="/content/download/윤리경영실천지침_SK텔링크_240304.pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li>
              <li>
                <span class="pdf_title">BP 윤리실천 서약서</span>
                <span class="pdf_txt"></span>
                <a
                  class="pdf_btn"
                  href="/content/download/BP_윤리실천_서약서_SK텔링크_240906.pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li>
              <!-- 2025/01/03 히든 처리 -->
              <!-- <li>
                <span class="pdf_title">부패방지 방침</span>
                <span class="pdf_txt"></span>
                <a
                  class="pdf_btn"
                  href="/content/download/SK텔링크_부패방지경영지침_240906.pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li> -->
            </ul>
            <!-- 2025/01/03 히든 처리 -->
            <!-- <div class="esg_content">
              <h5 class="esg_content_title">SK텔링크 부패방지 방침</h5>
              <p class="esg_content_sub">
                SK텔링크는 임직원과 모든 이해관계자와 함께 하는 지속가능 성장을
                추구하고<br class="pc-only" />
                ESG 경영을 통한 사회적 가치 실현을 위하여 ‘부패방지 방침’을
                아래와 같이 정하여 운영합니다.
              </p>
              <div class="esg_content_inner policy">
                <dl class="esg_inner_list">
                  <dt>제1조 (부패금지)</dt>
                  <dd>SK텔링크의 임직원은 부패 행위를 금지합니다.</dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제2조 (법규준수)</dt>
                  <dd>
                    SK텔링크의 임직원은 업무 활동에 적용되는 부패 관련 국내/외
                    법률 및 당사의 규정/지침을 준수합니다.
                  </dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제3조 (부패방지 방침)</dt>
                  <dd>
                    SK텔링크는 글로벌 기준에 맞는 부패방지 방침을 수립하고 실행
                    및 모니터링을 통하여 부패방지경영 수준을 지속적으로
                    개선합니다.
                  </dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제4조 (제보시스템)</dt>
                  <dd>
                    SK텔링크는 제보시스템을 활성화하고 선의의 제보자에 대한
                    보호조치를 시행하여 구성원들이 부패방지 활동에 부담 없이
                    참여할 수 있도록 운영합니다.
                  </dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제5조 (미 준수에 따른 결과)</dt>
                  <dd>
                    부패방침을 미 준수하는 임직원에 대해서는 관련 규정에 따라
                    엄정하게 처리하고 그 결과를 공개합니다.
                  </dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제6조 (권한 및 독립성)</dt>
                  <dd>
                    부패방지를 담당하는 조직 및 구성원의 권한 및 독립성을
                    보장합니다.
                  </dd>
                </dl>
                <dl class="esg_inner_list">
                  <dt>제7조 (실행의지)</dt>
                  <dd>
                    SK텔링크의 경영진은 부패방지 방침이 효율적으로 실행될 수
                    있도록 지원을 아끼지 않습니다.
                  </dd>
                </dl>
                <div class="esg_inner_signature">
                  <span class="ymd">2024년 8월 30일</span>
                  <span class="representative"
                    >SK텔링크 대표이사 사장 하성호</span
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="esg_report">
            <div class="report-title-wrap">
              <img
                src="/content/images/img_esg_ethical02.png"
                alt="윤리경영 상담/제보"
              />
              <h4 class="report-title">윤리경영 상담/제보</h4>
            </div>
            <p class="report-txt">
              SK텔링크는 임직원의 비윤리적인 업무 수행, 부정청탁 및 금품 등
              <br class="pc-only" />
              수수의 금지에 관한 법률 위반 행위, 기타 부정한 비리 행위에
              대하여<br class="pc-only" />
              상담 및 신고를 할 수 있는 채널을 운영하고 있습니다.<br
                class="pc-only"
              />
              어떠한 경우에도 상담/신고자의 신분 및 상담/신고 내용은<br
                class="pc-only"
              />
              보호되도록 운영하고 있으니 주저하지 마시고 아래 채널을 통해 접수해
              주시기 바랍니다.
            </p>

            <p class="emphasis-txt">
              (단, 사실에 근거하지 않은 비방 또는 개인 사생활에 관한 사항 등은
              처리되지 않을 수 있으니 참고 바랍니다.)
            </p>

            <div class="report-bottom-wrap">
              <span class="bottom-title">상담/제보 접수</span>
              <a
                class="btn_type04"
                href="https://ethics.sk.co.kr/"
                target="_blank"
                >바로가기</a
              >
              <span class="helper-txt">
                [참고] SK텔링크의 상품/서비스 관련 불만 및 이용불편 사항은
                고객센터로 전화 주시기 바랍니다.<br />
                (국제전화 1599-00700, 일반전화 1599-0999). 감사합니다.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "EsgEthicalManagement",
  metaInfo: {
    title: "윤리경영 | 지배구조 | ESG",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tab_type01 {
  border-top: 1px solid #dadada;
}
.esg {
  margin: 97px 0 0px;

  .esg_box {
    width: 1120px;
    margin: 0 auto;
    padding: 60px 0 20px;
    text-align: center;

    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }

    .inner {
      width: 100%;

      img {
        max-width: 785px;
      }
    }
  }

  .esg_pdf_list {
    margin-top: 90px;
  }

  .esg_content {
    margin-top: 137px;
  }

  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;
    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }

  .esg_report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;
    padding: 90px 20px;
    text-align: center;
    background-color: #f9f9f9;

    * {
      letter-spacing: -0.03em;
    }

    .report-title-wrap {
      text-align: center;

      img {
        width: 74px;
        height: 74px;
      }

      .report-title {
        margin-top: 30px;
        font-size: 44px;
        line-height: 44px;
        color: #444444;
      }
    }

    .report-txt {
      margin-top: 25px;
      font-size: 15px;
      line-height: 26px;

      > p {
        margin-top: 12px;
        font-size: 15px;
        line-height: 26px;
        color: #ff8522;
      }
    }

    .emphasis-txt {
      margin-top: 12px;
      font-size: 15px;
      line-height: 26px;
      color: #ff8522;
    }

    .report-bottom-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 85px;
      text-align: center;

      .bottom-title {
        font-size: 22px;
        line-height: 22px;
        font-weight: 4100;
        color: #444444;
      }

      .btn_type04 {
        margin-top: 20px;
      }

      .helper-txt {
        margin-top: 35px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}

@media (max-width: 1023px) {
  .esg {
    margin-top: 0;
    padding-bottom: 0;

    .cp_box {
      padding: 0 20px;
    }
    .esg_box {
      width: 100%;
      margin: 0;
      padding: 30px 15px 10px;
      th,
      td {
        padding: 5px;
        font-size: 13px;
        line-height: 1.3;
      }
      sup {
        line-height: inherit;
      }
    }

    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .esg_content {
      margin-top: 60px;
    }

    .esg_report {
      padding: 50px 20px;
      margin-top: 60px;

      .report-title-wrap {
        .report-title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .report-bottom-wrap {
        margin-top: 40px;

        .helper-txt {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
